const Icon = ({ type, className }) => {
  switch (type) {
    case 'dropdown':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className}>
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
        </svg>
      );
    case 'hamburger':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className}>
          <path d="M2 5v2h20V5H2zm0 6v2h20v-2H2zm0 6v2h20v-2H2z" />
        </svg>
      );
    case 'close':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className}>
          <path d="M4.707 3.293L3.293 4.707 10.586 12l-7.293 7.293 1.414 1.414L12 13.414l7.293 7.293 1.414-1.414L13.414 12l7.293-7.293-1.414-1.414L12 10.586 4.707 3.293z" />
        </svg>
      );
    case 'arrow':
      return (
        <svg
          width="10"
          height="17"
          viewBox="0 0 10 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <path d="M9 1L1.50008 8.49992L9 15.9998" stroke="#041C2C" strokeWidth="1.5" />
        </svg>
      );
    case 'pin':
      return (
        <svg
          width="24"
          height="29"
          viewBox="0 0 24 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.4853 20.506L12 29L3.51467 20.506C1.83646 18.8261 0.693582 16.6857 0.230571 14.3556C-0.232439 12.0255 0.00520913 9.61022 0.913465 7.41529C1.82172 5.22036 3.35979 3.34432 5.33318 2.02441C7.30656 0.704499 9.62663 0 12 0C14.3734 0 16.6934 0.704499 18.6668 2.02441C20.6402 3.34432 22.1783 5.22036 23.0865 7.41529C23.9948 9.61022 24.2324 12.0255 23.7694 14.3556C23.3064 16.6857 22.1635 18.8261 20.4853 20.506ZM12 14.6815C12.7072 14.6815 13.3855 14.4002 13.8856 13.8996C14.3857 13.399 14.6667 12.7201 14.6667 12.0121C14.6667 11.3041 14.3857 10.6252 13.8856 10.1246C13.3855 9.62395 12.7072 9.34271 12 9.34271C11.2928 9.34271 10.6145 9.62395 10.1144 10.1246C9.61429 10.6252 9.33333 11.3041 9.33333 12.0121C9.33333 12.7201 9.61429 13.399 10.1144 13.8996C10.6145 14.4002 11.2928 14.6815 12 14.6815Z"
            fill="white"
          />
        </svg>
      );
    case 'filter':
      return (
        <svg
          width="16"
          height="7"
          viewBox="0 0 16 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.3119 5.83398H5.40123C5.27099 5.83398 5.14608 5.8852 5.05399 5.97638C4.96189 6.06755 4.91016 6.19121 4.91016 6.32015C4.91016 6.44908 4.96189 6.57274 5.05399 6.66391C5.14608 6.75509 5.27099 6.80631 5.40123 6.80631H10.3119C10.4422 6.80631 10.5671 6.75509 10.6592 6.66391C10.7513 6.57274 10.803 6.44908 10.803 6.32015C10.803 6.19121 10.7513 6.06755 10.6592 5.97638C10.5671 5.8852 10.4422 5.83398 10.3119 5.83398Z"
            fill="#041C2C"
          />
          <path
            d="M12.7676 2.91699H2.94615C2.81591 2.91699 2.691 2.96821 2.59891 3.05938C2.50682 3.15056 2.45508 3.27422 2.45508 3.40315C2.45508 3.53209 2.50682 3.65575 2.59891 3.74692C2.691 3.83809 2.81591 3.88931 2.94615 3.88931H12.7676C12.8978 3.88931 13.0227 3.83809 13.1148 3.74692C13.2069 3.65575 13.2586 3.53209 13.2586 3.40315C13.2586 3.27422 13.2069 3.15056 13.1148 3.05938C13.0227 2.96821 12.8978 2.91699 12.7676 2.91699Z"
            fill="#041C2C"
          />
          <path
            d="M15.2232 0H0.491071C0.360831 0 0.235925 0.0512245 0.143831 0.142405C0.0517377 0.233585 0 0.357251 0 0.486199C0 0.615147 0.0517377 0.738814 0.143831 0.829994C0.235925 0.921174 0.360831 0.972398 0.491071 0.972398H15.2232C15.3535 0.972398 15.4784 0.921174 15.5705 0.829994C15.6625 0.738814 15.7143 0.615147 15.7143 0.486199C15.7143 0.357251 15.6625 0.233585 15.5705 0.142405C15.4784 0.0512245 15.3535 0 15.2232 0Z"
            fill="#041C2C"
          />
        </svg>
      );
    case 'muted':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={24}
          height={24}
          stroke="#fff"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="miter"
          fill="none"
          color="#fff"
          className={className}
        >
          <path d="M18 10L22 14M18 14L22 10" /> <path d="M13 5v14l-5-4H3V9h5z" />
          <path strokeLinecap="round" d="M13 14c1.5-1 1.5-3 0-4" />
        </svg>
      );
    case 'loud':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={24}
          height={24}
          stroke="#fff"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="miter"
          fill="none"
          color="#fff"
          className={className}
        >
          <path d="M13 5v14l-5-4H3V9h5z" />
          <path strokeLinecap="round" d="M13 14c1.5-1 1.5-3 0-4" />
          <path d="M16 16C18.0858253 13.9141747 18.0858253 10.0858253 16 8M18 19C21.98552 15.01448 22.0076803 9.00768033 18 5" />
        </svg>
      );
    case 'select':
      return (
        <svg
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <path d="M9 1L5.00008 4.99992L1.00016 1" stroke="inherith" />
        </svg>
      );
    case 'paypal':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 32 20"
        >
          <g clipPath="url(#a)">
            <path
              stroke="currentColor"
              strokeOpacity=".3"
              d="M2.53.5h26.94c1.1 0 2.03.88 2.03 2v15c0 1.14-.87 2-2.03 2H2.53a2.02 2.02 0 0 1-2.03-2v-15c0-1.14.87-2 2.03-2Z"
            />
            <path
              fill="currentColor"
              d="M8.4 7.34H6.85c-.11 0-.2.07-.22.18L6 11.5c-.01.08.05.15.13.15h.75c.1 0 .2-.08.2-.18l.18-1.08c.02-.1.1-.18.21-.18h.5c1.02 0 1.61-.5 1.77-1.48.07-.43 0-.77-.2-1-.23-.27-.62-.4-1.14-.4Zm.18 1.45c-.08.56-.51.56-.92.56h-.24l.17-1.04c0-.06.06-.11.12-.11h.11c.28 0 .55 0 .68.16.09.1.11.24.08.43ZM13.05 8.78h-.75a.13.13 0 0 0-.12.1l-.04.21-.05-.07c-.16-.24-.52-.31-.88-.31-.82 0-1.53.62-1.66 1.5-.07.43.03.85.28 1.14.22.27.55.38.93.38.67 0 1.03-.43 1.03-.43l-.03.2c-.02.09.05.16.13.16h.67c.1 0 .2-.08.21-.18l.4-2.55a.13.13 0 0 0-.12-.15Zm-1.04 1.44a.83.83 0 0 1-.84.72.64.64 0 0 1-.5-.2.63.63 0 0 1-.11-.53.83.83 0 0 1 .83-.71c.21 0 .39.07.5.2.11.13.16.32.12.53ZM17.02 8.78h-.75a.22.22 0 0 0-.18.1l-1.03 1.51-.44-1.46a.22.22 0 0 0-.2-.15h-.74a.13.13 0 0 0-.12.17l.82 2.42-.77 1.09c-.07.09 0 .2.1.2h.75c.07 0 .13-.03.18-.09l2.49-3.6c.05-.08 0-.2-.11-.2ZM19.5 7.34h-1.56c-.1 0-.2.07-.21.18l-.63 3.99c-.02.08.04.15.12.15h.8c.08 0 .14-.06.15-.13l.18-1.13c.02-.1.1-.18.22-.18h.49c1.02 0 1.61-.5 1.77-1.48.07-.43 0-.77-.2-1-.22-.27-.61-.4-1.13-.4Zm.18 1.45c-.09.56-.52.56-.93.56h-.23l.16-1.04c.01-.06.07-.11.13-.11h.1c.29 0 .55 0 .69.16.08.1.1.24.07.43ZM24.14 8.78h-.74a.13.13 0 0 0-.13.1l-.03.21-.05-.07c-.16-.24-.52-.31-.88-.31-.82 0-1.53.62-1.66 1.5-.07.43.03.85.27 1.14.23.27.56.38.94.38.67 0 1.03-.43 1.03-.43l-.03.2c-.02.09.05.16.12.16h.68c.1 0 .2-.08.2-.18l.41-2.55a.13.13 0 0 0-.13-.15Zm-1.03 1.44a.83.83 0 0 1-.84.72.64.64 0 0 1-.5-.2.63.63 0 0 1-.12-.53.84.84 0 0 1 .84-.71c.2 0 .38.07.5.2.1.13.15.32.12.53ZM25.02 7.45l-.63 4.06c-.02.08.04.15.12.15h.64c.11 0 .2-.08.22-.18l.63-4a.13.13 0 0 0-.13-.14h-.72a.13.13 0 0 0-.13.1Z"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M0 0h32v20H0z" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'ideal':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 32 20"
        >
          <g clipPath="url(#a)">
            <path
              stroke="currentColor"
              strokeOpacity=".3"
              d="M2.53.5h26.94c1.1 0 2.03.88 2.03 2v15c0 1.14-.87 2-2.03 2H2.53a2.02 2.02 0 0 1-2.03-2v-15c0-1.14.87-2 2.03-2Z"
            />
            <path
              fill="#fff"
              d="M9 4.93v10.51c0 .51.42.9.9.9h6.3c4.75 0 6.8-2.65 6.8-6.17S20.95 4 16.2 4H9.93c-.5.03-.93.45-.93.93Z"
            />
            <path
              fill="#041C2C"
              d="M13.22 6.62v7.77h3.37c3.07 0 4.4-1.75 4.4-4.19 0-2.35-1.33-4.15-4.4-4.15h-2.8c-.3-.03-.57.24-.57.57Z"
            />
            <path
              fill="#041C2C"
              d="M16.2 15.53h-5.45a.87.87 0 0 1-.88-.87V5.75c0-.49.4-.88.88-.88h5.45c5.17 0 5.96 3.34 5.96 5.33 0 3.43-2.11 5.33-5.96 5.33ZM10.75 5.17a.57.57 0 0 0-.58.58v8.9c0 .34.28.58.58.58h5.45c3.64 0 5.66-1.8 5.66-5.06 0-4.36-3.56-5.03-5.66-5.03l-5.45.03Z"
            />
            <path
              fill="#fff"
              d="M14.42 9.15c.12 0 .24.03.33.06.1.03.18.09.27.18l.18.27c.03.12.06.24.06.4 0 .14-.03.26-.06.35-.03.12-.09.21-.15.3a.66.66 0 0 1-.27.22c-.09.06-.24.06-.36.06h-.81V9.12l.8.03Zm-.03 1.5c.06 0 .12 0 .18-.03l.15-.09c.03-.06.1-.09.1-.18.02-.06.02-.15.02-.27 0-.09 0-.18-.03-.24s-.06-.15-.09-.18c-.03-.06-.09-.09-.15-.12s-.15-.03-.24-.03h-.3v1.17l.36-.03ZM16.92 9.15v.33h-1v.42h.9v.3h-.9v.45h1V11H15.5V9.12l1.42.03ZM18.3 9.15l.7 1.87h-.43l-.15-.43h-.69l-.15.43h-.42l.7-1.87h.44Zm.03 1.14-.24-.69-.24.7h.48ZM19.66 9.15v1.5h.9V11h-1.32V9.12c.03.03.42.03.42.03Z"
            />
            <path
              fill="#041C2C"
              d="M11.68 10.92a.84.84 0 1 0 0-1.68.84.84 0 0 0 0 1.68ZM12.34 14.39c-.72 0-1.3-.58-1.3-1.3v-1.02c0-.36.3-.66.67-.66.36 0 .66.3.66.66l-.03 2.32Z"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M0 0h32v20H0z" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'visa':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 32 20"
        >
          <g clipPath="url(#a)">
            <path
              stroke="currentColor"
              strokeOpacity=".3"
              d="M2.53.5h26.94c1.1 0 2.03.88 2.03 2v15c0 1.14-.87 2-2.03 2H2.53a2.02 2.02 0 0 1-2.03-2v-15c0-1.14.87-2 2.03-2Z"
            />
            <path
              fill="currentColor"
              d="M23.79 8.6h-.23c-.3.74-.54 1.1-.77 2.2h1.45c-.22-1.1-.22-1.6-.45-2.2ZM26 12.94h-1.3c-.07 0-.07 0-.15-.07l-.15-.66-.08-.15H22.5c-.08 0-.15 0-.15.15l-.23.66c0 .07-.08.07-.08.07h-1.6l.15-.37 2.21-4.98c0-.36.23-.51.61-.51h1.15c.08 0 .15 0 .15.15l1.07 4.76c.08.3.15.51.15.8.08.08.08.08.08.15Zm-10.23-.22.3-1.32c.08 0 .16.08.16.08.53.22 1.07.36 1.6.29.15 0 .38-.07.54-.15.38-.14.38-.51.07-.8-.15-.15-.38-.22-.6-.37-.31-.15-.62-.3-.85-.51-.91-.74-.6-1.76-.07-2.27.45-.3.68-.59 1.3-.59.91 0 1.9 0 2.36.15h.08c-.08.44-.16.8-.3 1.24a3.3 3.3 0 0 0-1.15-.3c-.23 0-.46 0-.7.08-.14 0-.22.08-.3.15a.34.34 0 0 0 0 .51l.39.3c.3.14.6.29.84.43.38.22.76.59.83 1.03.16.66-.07 1.24-.68 1.68-.38.3-.54.44-1.07.44-1.07 0-1.9.08-2.6-.14-.07.14-.07.14-.15.07Zm-2.67.22c.08-.51.08-.51.15-.73.38-1.61.77-3.3 1.07-4.91.08-.15.08-.22.23-.22h1.37c-.15.88-.3 1.54-.53 2.34-.23 1.1-.46 2.2-.76 3.3 0 .15-.08.15-.23.15l-1.3.07ZM6 7.22c0-.08.15-.15.23-.15h2.6c.38 0 .68.22.76.59l.68 3.22c0 .07 0 .07.08.15 0-.08.08-.08.08-.08l1.6-3.73c-.08-.08 0-.15.08-.15h1.6c0 .07 0 .07-.08.15l-2.36 5.34c-.08.15-.08.22-.16.3-.07.07-.22 0-.38 0H9.6c-.08 0-.15 0-.15-.15L8.2 8.17a1.56 1.56 0 0 0-.68-.44 5.25 5.25 0 0 0-1.45-.37L6 7.22Z"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M0 0h32v20H0z" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'master':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 32 20"
        >
          <g clipPath="url(#a)">
            <path
              stroke="currentColor"
              strokeOpacity=".3"
              d="M2.53.5h26.94c1.1 0 2.03.88 2.03 2v15c0 1.14-.87 2-2.03 2H2.53a2.02 2.02 0 0 1-2.03-2v-15c0-1.14.87-2 2.03-2Z"
            />
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M16 12.6a4.94 4.94 0 1 1 0-7.77 4.94 4.94 0 1 1 0 7.78Zm-1.89-3.88A4.94 4.94 0 0 1 16 4.83a4.95 4.95 0 0 1 0 7.78 4.94 4.94 0 0 1-1.89-3.89Zm2.7 3.84a4.44 4.44 0 1 0 0-7.67 5.44 5.44 0 0 1 0 7.67Z"
              clipRule="evenodd"
            />
            <path
              fill="currentColor"
              d="M23.53 11.63v.16h-.04v-.16h-.06v-.04h.16v.04h-.06ZM23.85 11.6v.19h-.04v-.15l-.05.13h-.04l-.05-.13v.15h-.04v-.2h.05l.06.14.06-.14h.05ZM10.91 15.33v.82h-.29v-.73c0-.22-.1-.34-.29-.34a.3.3 0 0 0-.32.34v.73h-.29v-.73c0-.22-.1-.34-.28-.34a.3.3 0 0 0-.31.21.3.3 0 0 0-.01.13v.73h-.3v-1.31h.3V15a.43.43 0 0 1 .38-.2.48.48 0 0 1 .43.24.5.5 0 0 1 .46-.23.49.49 0 0 1 .52.52ZM14.71 14.84h.47v.26h-.47v.6c0 .13.05.21.2.21.08 0 .17-.03.24-.07l.09.24c-.11.07-.23.1-.36.1-.34 0-.46-.18-.46-.48v-.6h-.26v-.26h.26v-.4h.3v.4ZM17.42 14.86a.39.39 0 0 1 .2-.05c.06 0 .13 0 .2.03l-.09.27a.47.47 0 0 0-.18-.03c-.18 0-.28.12-.28.34v.73H17v-1.31h.28V15a.39.39 0 0 1 .15-.14ZM13.4 14.8c.18 0 .37.05.53.14l-.12.23a.85.85 0 0 0-.41-.1c-.16 0-.25.05-.25.15 0 .08.1.1.22.12l.14.02c.28.05.45.17.45.4 0 .25-.21.43-.6.43-.2 0-.4-.06-.56-.17l.13-.23c.13.1.28.14.44.14.2 0 .3-.06.3-.16 0-.07-.08-.11-.23-.14l-.14-.02c-.3-.04-.45-.17-.45-.38 0-.26.21-.42.55-.42ZM21.38 14.86a.39.39 0 0 1 .2-.05c.07 0 .14 0 .2.03l-.08.27a.47.47 0 0 0-.18-.03c-.19 0-.28.12-.28.34v.73h-.29v-1.31h.29V15a.39.39 0 0 1 .14-.14ZM17.95 15.77a.66.66 0 0 1-.05-.27.66.66 0 0 1 .7-.7c.18 0 .34.05.48.16l-.14.23a.58.58 0 0 0-.34-.11.42.42 0 0 0 0 .84c.12 0 .24-.05.34-.12l.14.23c-.14.1-.3.16-.48.16a.66.66 0 0 1-.65-.42Z"
            />
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M20.58 14.84v1.31h-.29V16a.5.5 0 0 1-.41.2.7.7 0 0 1 0-1.38.5.5 0 0 1 .41.19v-.16h.29Zm-.99.44a.4.4 0 0 0-.05.38.4.4 0 0 0 .37.26.4.4 0 1 0-.32-.64ZM15.6 15.02c.12-.13.3-.2.47-.21.37 0 .62.28.62.69v.1h-.98a.36.36 0 0 0 .4.33c.13 0 .27-.05.38-.14l.14.21a.8.8 0 0 1-.54.19.69.69 0 0 1-.49-1.17Zm.7.13a.33.33 0 0 0-.24-.09.34.34 0 0 0-.35.32h.69a.33.33 0 0 0-.1-.23ZM23.23 14.31v1.84h-.29V16a.5.5 0 0 1-.41.2.7.7 0 0 1 0-1.38.5.5 0 0 1 .41.19v-.69h.29Zm-.99.97a.4.4 0 0 0 .54.57.4.4 0 1 0-.54-.57ZM23.75 16.04a.05.05 0 0 0-.04 0h-.05v.11h.02v-.04h.01l.04.04h.03l-.04-.04c.01 0 .02 0 .03-.02a.03.03 0 0 0 .01-.02.04.04 0 0 0-.01-.03Zm-.07.05v-.04h.05v.02a.01.01 0 0 1 0 .01h-.05Z"
              clipRule="evenodd"
            />
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M23.76 15.97a.13.13 0 0 0-.05 0 .13.13 0 0 0-.13.07.13.13 0 0 0 .05.16.13.13 0 0 0 .13.01.14.14 0 0 0 .07-.07.13.13 0 0 0-.03-.14.14.14 0 0 0-.04-.03Zm-.02.22a.1.1 0 0 1-.14-.1.1.1 0 0 1 .07-.1.1.1 0 0 1 .07.2ZM12.52 14.84v1.31h-.29V16a.5.5 0 0 1-.41.2.69.69 0 0 1 0-1.38.5.5 0 0 1 .41.19v-.16h.29Zm-.99.44a.4.4 0 1 0 .64.48.4.4 0 0 0-.64-.48Z"
              clipRule="evenodd"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M0 0h32v20H0z" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'maestro':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 27 17"
          fill="none"
          className={className}
        >
          <g clipPath="url(#clip0_1527_3651)">
            <path
              d="M2.55263 1.25H24.4474C25.2819 1.25 26 1.92199 26 2.78125V14.9688C26 15.8387 25.3393 16.5 24.4474 16.5H2.55263C1.71814 16.5 1 15.828 1 14.9688V2.78125C1 1.91127 1.66067 1.25 2.55263 1.25Z"
              stroke="#041C2C"
              strokeOpacity={0.1}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.5001 5.00071C12.953 4.57072 12.296 4.30332 11.6042 4.22907C10.9123 4.15481 10.2135 4.2767 9.58766 4.58082C8.96179 4.88494 8.43412 5.35901 8.06495 5.94885C7.69578 6.53868 7.5 7.22048 7.5 7.91633C7.5 8.61217 7.69578 9.29397 8.06495 9.88381C8.43412 10.4736 8.96179 10.9477 9.58766 11.2518C10.2135 11.556 10.9123 11.6778 11.6042 11.6036C12.296 11.5293 12.9531 11.2619 13.5001 10.8319C14.0472 11.2619 14.7041 11.5293 15.396 11.6036C16.0878 11.6778 16.7866 11.5559 17.4125 11.2518C18.0383 10.9477 18.566 10.4736 18.9351 9.88378C19.3043 9.29394 19.5 8.61214 19.5 7.91631C19.5 7.22048 19.3043 6.53869 18.9351 5.94885C18.566 5.35902 18.0383 4.88495 17.4125 4.58083C16.7866 4.27671 16.0878 4.15481 15.396 4.22906C14.7041 4.30332 14.0471 4.57072 13.5001 5.00071ZM12.4559 6.29528C12.2105 6.80041 12.0832 7.35474 12.0837 7.91633C12.0832 8.47791 12.2105 9.03225 12.4559 9.53737C12.7013 10.0425 13.0583 10.4851 13.5001 10.8319C13.9415 10.4848 14.2983 10.042 14.5436 9.53701C14.789 9.03197 14.9164 8.4778 14.9164 7.91631C14.9164 7.35482 14.789 6.80066 14.5436 6.29561C14.2983 5.79059 13.9415 5.34785 13.5001 5.00079C13.0584 5.3475 12.7013 5.79017 12.4559 6.29528ZM14.1068 10.7925C14.5124 11.0301 14.9652 11.1802 15.436 11.2307C16.0579 11.2975 16.686 11.1879 17.2486 10.9145C17.8111 10.6411 18.2854 10.215 18.6172 9.68483C18.9491 9.15464 19.125 8.54179 19.125 7.91633C19.125 7.29086 18.9491 6.67798 18.6172 6.1478C18.2854 5.61761 17.8111 5.19148 17.2486 4.91812C16.686 4.64475 16.0579 4.53518 15.436 4.60193C14.9652 4.65246 14.5124 4.80248 14.1068 5.04008C14.4223 5.35812 14.6842 5.72675 14.8809 6.13175C15.1511 6.68786 15.2915 7.29805 15.2915 7.91631C15.2915 8.53457 15.1511 9.14477 14.8809 9.70088C14.6842 10.1059 14.4223 10.4745 14.1068 10.7925Z"
              fill="black"
            />
            <path
              d="M11.1279 12.8723V13.4894H10.9119V12.9422C10.9119 12.7756 10.8419 12.683 10.6959 12.683C10.6621 12.6815 10.6283 12.6873 10.597 12.7002C10.5657 12.7131 10.5376 12.7326 10.5146 12.7575C10.4917 12.7824 10.4745 12.8121 10.4642 12.8443C10.454 12.8766 10.4509 12.9107 10.4552 12.9443V13.4894H10.2392V12.9422C10.2392 12.7756 10.1672 12.683 10.0252 12.683C9.9913 12.6811 9.95739 12.6867 9.92591 12.6995C9.89444 12.7123 9.86618 12.7318 9.84316 12.7568C9.82015 12.7818 9.80296 12.8115 9.79281 12.844C9.78267 12.8764 9.77984 12.9106 9.78451 12.9443V13.4894H9.56849V12.5061H9.78246V12.6274C9.81359 12.5801 9.85654 12.5418 9.90705 12.5161C9.95757 12.4905 10.0139 12.4785 10.0705 12.4814C10.1354 12.4786 10.1998 12.4934 10.257 12.5242C10.3142 12.5549 10.3621 12.6006 10.3955 12.6563C10.4322 12.5989 10.4837 12.5524 10.5444 12.5216C10.6052 12.4908 10.6732 12.477 10.7411 12.4814C10.7931 12.4783 10.8451 12.4864 10.8937 12.505C10.9423 12.5236 10.9863 12.5523 11.0229 12.5893C11.0595 12.6263 11.0878 12.6707 11.1059 12.7195C11.124 12.7683 11.1315 12.8203 11.1279 12.8723Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.3335 12.5061V13.4894H12.1195V13.3701C12.083 13.4172 12.0357 13.4548 11.9817 13.4799C11.9276 13.5049 11.8684 13.5167 11.8089 13.5141C11.6763 13.5076 11.5513 13.4504 11.4598 13.3543C11.3682 13.2581 11.3172 13.1305 11.3172 12.9978C11.3172 12.865 11.3682 12.7374 11.4598 12.6413C11.5513 12.5451 11.6763 12.4879 11.8089 12.4814C11.8684 12.4788 11.9276 12.4906 11.9817 12.5156C12.0357 12.5407 12.083 12.5783 12.1195 12.6254V12.5061H12.3335ZM11.5955 12.8373C11.5609 12.8839 11.5406 12.9397 11.5373 12.9977C11.5346 13.0384 11.5403 13.0792 11.5541 13.1175C11.5679 13.1558 11.5895 13.1909 11.6175 13.2205C11.6455 13.25 11.6794 13.2735 11.7169 13.2893C11.7545 13.3052 11.7949 13.3131 11.8356 13.3125C11.8937 13.3123 11.9505 13.2951 11.999 13.263C12.0474 13.2309 12.0854 13.1854 12.1083 13.1319C12.1312 13.0785 12.1379 13.0195 12.1277 12.9623C12.1174 12.9051 12.0907 12.8521 12.0507 12.8099C12.0107 12.7678 11.9593 12.7382 11.9027 12.7249C11.8461 12.7116 11.7869 12.7152 11.7323 12.7351C11.6777 12.7551 11.6302 12.7906 11.5955 12.8373Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.1226 12.5198C17.0555 12.493 16.9839 12.4795 16.9116 12.4802C16.8394 12.4795 16.7677 12.493 16.7006 12.5198C16.6382 12.5447 16.5813 12.5816 16.5332 12.6286C16.486 12.6754 16.4484 12.731 16.4228 12.7924C16.3694 12.9241 16.3694 13.0714 16.4228 13.2031C16.4484 13.2645 16.4859 13.3202 16.5332 13.3669C16.5813 13.4139 16.6382 13.4509 16.7006 13.4757C16.8363 13.5285 16.9869 13.5285 17.1226 13.4757C17.1852 13.4508 17.2422 13.4138 17.2905 13.3669C17.3381 13.3203 17.3758 13.2646 17.4014 13.2031C17.4548 13.0714 17.4548 12.9241 17.4014 12.7924C17.3758 12.7309 17.3381 12.6752 17.2905 12.6286C17.2422 12.5817 17.1852 12.5447 17.1226 12.5198ZM16.7894 12.7056C16.8283 12.69 16.8698 12.6822 16.9116 12.6825C16.9535 12.6822 16.995 12.69 17.0338 12.7056C17.0705 12.7204 17.1038 12.7424 17.1319 12.7703C17.1601 12.7988 17.1823 12.8327 17.1971 12.8699C17.2286 12.9523 17.2286 13.0433 17.1971 13.1256C17.1823 13.1629 17.1601 13.1967 17.1319 13.2252C17.1038 13.2531 17.0705 13.2751 17.0338 13.2899C16.9553 13.3207 16.868 13.3207 16.7894 13.2899C16.7528 13.2752 16.7197 13.2532 16.6919 13.2252C16.6639 13.1966 16.6419 13.1628 16.6272 13.1256C16.5957 13.0433 16.5957 12.9523 16.6272 12.8699C16.6419 12.8328 16.6639 12.7989 16.6919 12.7703C16.7197 12.7423 16.7529 12.7203 16.7894 12.7056Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.0307 12.4814C13.3064 12.4814 13.4956 12.6912 13.4977 12.9977C13.4977 13.0266 13.4956 13.0533 13.4936 13.08H12.7632C12.7714 13.1505 12.8067 13.215 12.8616 13.26C12.9165 13.3049 12.9867 13.3267 13.0574 13.3207C13.1615 13.3192 13.2622 13.283 13.3434 13.2179L13.4483 13.3763C13.3354 13.4702 13.1919 13.5193 13.0451 13.5141C12.9115 13.5109 12.7844 13.456 12.6904 13.361C12.5964 13.266 12.5429 13.1383 12.541 13.0047C12.5392 12.8711 12.5891 12.7419 12.6804 12.6444C12.7717 12.5468 12.8973 12.4884 13.0307 12.4814ZM12.8473 12.7388C12.7985 12.7834 12.7691 12.8453 12.7653 12.9114H13.2755C13.274 12.8467 13.247 12.7853 13.2004 12.7406C13.1537 12.6959 13.0912 12.6715 13.0266 12.6727C12.9605 12.6705 12.8962 12.6942 12.8473 12.7388Z"
              fill="black"
            />
            <path
              d="M14.0922 12.6727C14.1999 12.6743 14.3054 12.7034 14.3987 12.7571L14.4913 12.5843C14.3702 12.5122 14.231 12.4765 14.0901 12.4814C13.8412 12.4814 13.6808 12.6007 13.6808 12.7961C13.6808 12.9566 13.8001 13.0554 14.0202 13.0862L14.121 13.1006C14.2383 13.1171 14.2938 13.1479 14.2938 13.2035C14.2938 13.2796 14.2156 13.3228 14.0696 13.3228C13.9521 13.3262 13.8369 13.29 13.7425 13.2199L13.6417 13.3866C13.7663 13.4736 13.9156 13.5183 14.0675 13.5141C14.3514 13.5141 14.516 13.3804 14.516 13.1932C14.516 13.0204 14.3864 12.9299 14.1724 12.899L14.0716 12.8846C13.9791 12.8723 13.905 12.8538 13.905 12.7879C13.905 12.7159 13.9749 12.6727 14.0922 12.6727Z"
              fill="black"
            />
            <path
              d="M15.0771 12.7015H15.4268V12.5061H15.0771L15.077 12.2078H14.861L14.8611 12.5061H14.6615V12.7015H14.8611V13.15C14.8611 13.3784 14.9495 13.5141 15.2026 13.5141C15.2971 13.5144 15.3898 13.488 15.47 13.438L15.4083 13.2549C15.3509 13.2891 15.2858 13.3083 15.219 13.3105C15.1121 13.3105 15.0771 13.2446 15.0771 13.1459V12.7015Z"
              fill="black"
            />
            <path
              d="M16.3195 12.5081C16.2706 12.4906 16.2192 12.4816 16.1673 12.4814C16.1151 12.4798 16.0635 12.4924 16.0179 12.5178C15.9722 12.5431 15.9343 12.5803 15.9081 12.6254V12.5061H15.6962V13.4894H15.9101V12.9381C15.9101 12.7756 15.9801 12.6851 16.12 12.6851C16.1657 12.6844 16.2112 12.6928 16.2537 12.7097L16.3195 12.5081Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.6812 13.4062C17.6734 13.4005 17.6639 13.3977 17.6542 13.3983H17.6123V13.4893H17.6317V13.4552H17.6406L17.6682 13.4893H17.6914L17.6617 13.455C17.6696 13.4544 17.6772 13.4513 17.6832 13.446C17.6856 13.4436 17.6876 13.4407 17.6889 13.4375C17.6902 13.4343 17.6908 13.4308 17.6907 13.4274C17.6909 13.4234 17.6901 13.4193 17.6885 13.4157C17.6868 13.412 17.6844 13.4087 17.6812 13.4062ZM17.6317 13.4396V13.4154H17.654C17.6583 13.4152 17.6626 13.4162 17.6663 13.4184C17.667 13.4188 17.6677 13.4194 17.6682 13.4201C17.6688 13.4207 17.6693 13.4215 17.6697 13.4223C17.6705 13.4238 17.6709 13.4256 17.6708 13.4274C17.6709 13.4292 17.6706 13.431 17.6698 13.4326C17.669 13.4342 17.6678 13.4356 17.6663 13.4366C17.6626 13.4388 17.6583 13.4398 17.654 13.4396H17.6317Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.6872 13.354C17.675 13.3488 17.6618 13.3462 17.6485 13.3463C17.635 13.3462 17.6215 13.3488 17.609 13.354C17.5972 13.3588 17.5864 13.366 17.5773 13.375C17.5683 13.3839 17.561 13.3945 17.556 13.4062C17.5509 13.4182 17.5482 13.4311 17.5482 13.4441C17.5482 13.4572 17.5509 13.4701 17.556 13.4821C17.5638 13.5001 17.5768 13.5153 17.5932 13.526C17.6097 13.5367 17.6289 13.5422 17.6485 13.542C17.6618 13.5421 17.675 13.5395 17.6872 13.5342C17.699 13.5292 17.7098 13.5221 17.7189 13.5131C17.7279 13.5042 17.7352 13.4937 17.7402 13.4821C17.7454 13.4701 17.748 13.4572 17.748 13.4441C17.748 13.4311 17.7454 13.4182 17.7402 13.4062C17.7352 13.3945 17.728 13.3839 17.7189 13.375C17.7098 13.366 17.699 13.3589 17.6872 13.354ZM17.678 13.5145C17.6687 13.5185 17.6586 13.5205 17.6485 13.5205C17.6384 13.5205 17.6284 13.5186 17.619 13.5147C17.6097 13.5109 17.6012 13.5053 17.594 13.4981C17.5798 13.4838 17.5718 13.4644 17.5718 13.4442C17.5718 13.4239 17.5798 13.4045 17.594 13.3902C17.601 13.3833 17.6093 13.3778 17.6184 13.374C17.6279 13.3701 17.6382 13.368 17.6485 13.3681C17.6586 13.368 17.6687 13.37 17.678 13.374C17.6895 13.3789 17.6996 13.3865 17.7075 13.3963C17.7153 13.406 17.7206 13.4175 17.723 13.4297C17.7254 13.442 17.7247 13.4546 17.721 13.4666C17.7174 13.4785 17.7108 13.4893 17.702 13.4981C17.6951 13.505 17.687 13.5106 17.678 13.5145Z"
              fill="black"
            />
            <path
              d="M19.1463 10.2143V10.0949H19.1944V10.0706H19.0719V10.0949H19.12V10.2143H19.1463Z"
              fill="black"
            />
            <path
              d="M19.3843 10.2143V10.0704H19.3467L19.3035 10.1694L19.2603 10.0704H19.2227V10.2143H19.2492V10.1057L19.2898 10.1993H19.3173L19.3578 10.1055V10.2143H19.3843Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_1527_3651">
              <rect width={26} height={16.25} fill="white" transform="translate(0.5 0.75)" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'googlepay':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          className={className}
          viewBox="0 0 32 20"
        >
          <g clipPath="url(#a)">
            <path
              stroke="currentColor"
              strokeOpacity="0.3"
              d="M2.53.5h26.94c1.1 0 2.03.88 2.03 2v15c0 1.14-.87 2-2.03 2H2.53a2.02 2.02 0 0 1-2.03-2v-15c0-1.14.87-2 2.03-2Z"
            />
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M15.38 11.86V9.93h1c.4 0 .75-.14 1.03-.41l.07-.07a1.4 1.4 0 0 0-.07-1.95 1.42 1.42 0 0 0-1.03-.41h-1.6v4.77h.6Zm0-2.52V7.67h1.01c.22 0 .43.09.58.24.33.32.33.85.02 1.18a.78.78 0 0 1-.6.25h-1.01Zm4.92-.5a1.5 1.5 0 0 0-1.06-.35c-.57 0-1 .21-1.3.63l.54.34a.92.92 0 0 1 1.38-.2c.16.13.25.33.25.54v.14a1.8 1.8 0 0 0-.89-.2c-.42 0-.75.1-1 .3a.98.98 0 0 0-.38.8c-.01.31.12.6.35.8.24.22.54.33.9.33.4 0 .74-.2.99-.56h.03v.45h.57V9.85c0-.43-.12-.76-.38-1Zm-1.64 2.42a.5.5 0 0 1-.2-.4c0-.18.08-.33.24-.45.17-.12.37-.18.62-.18.34 0 .6.08.79.23a.9.9 0 0 1-.3.67.95.95 0 0 1-.68.29.73.73 0 0 1-.47-.16Zm3.32 2.03L24 8.6h-.66l-.93 2.35h-.01l-.96-2.35h-.65l1.32 3.06-.75 1.64h.62Z"
              clipRule="evenodd"
            />
            <path
              fill="currentColor"
              d="M13.3 9.5c0-.18-.02-.37-.05-.55H10.7v1.06h1.46c-.06.33-.25.64-.54.83v.68h.87c.51-.47.8-1.18.8-2.01Z"
            />
            <path
              fill="currentColor"
              d="M10.7 12.19c.73 0 1.34-.25 1.8-.67l-.88-.68a1.62 1.62 0 0 1-2.43-.87h-.9v.7a2.7 2.7 0 0 0 2.41 1.52Z"
            />
            <path
              fill="currentColor"
              d="M9.19 9.97c-.12-.34-.12-.7 0-1.05v-.7h-.9a2.75 2.75 0 0 0 0 2.46l.9-.7Z"
            />
            <path
              fill="currentColor"
              d="M10.7 7.79c.39 0 .76.14 1.04.41l.77-.78a2.59 2.59 0 0 0-1.8-.71 2.7 2.7 0 0 0-2.42 1.5l.9.71c.21-.65.8-1.13 1.51-1.13Z"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M0 0h32v20H0z" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'fullLengthArrow':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 1233 9"
          width="100%"
          height="100%"
        >
          <path
            fill="#041C2C"
            d="M.65 4.15a.5.5 0 0 0 0 .7l3.18 3.19a.5.5 0 1 0 .7-.71L1.72 4.5l2.83-2.83a.5.5 0 1 0-.71-.7L.65 4.14Zm1231.7.7a.5.5 0 0 0 0-.7l-3.18-3.19a.5.5 0 0 0-.71 0 .51.51 0 0 0 0 .71l2.83 2.83-2.83 2.83a.51.51 0 0 0 0 .7c.2.2.52.2.71 0l3.18-3.18ZM1 5h1231V4H1v1Z"
            opacity=".3"
          />
        </svg>
      );
    case 'fullLengthArrowMobile':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 259 9"
          width="100%"
          height="100%"
        >
          <path
            fill="#041C2C"
            d="M.6 4.1c-.1.2-.1.6 0 .8L3.8 8a.5.5 0 1 0 .7-.7L1.7 4.5l2.8-2.8a.5.5 0 1 0-.7-.7L.6 4Zm257.8.8c.1-.2.1-.6 0-.8L255.2 1a.5.5 0 0 0-.7.7l2.8 2.8-2.8 2.8a.5.5 0 0 0 .7.7l3.2-3.1ZM1 5h257V4H1v1Z"
            opacity=".3"
          />
        </svg>
      );
    case 'google':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 17 17"
        >
          <path
            fill="currentColor"
            d="M15.54 8.66663c0-.52-.0467-1.02-.1333-1.5H8.5v2.83667h3.9467c-.17.9167-.6867 1.6933-1.4634 2.2133v1.84h2.37C14.74 12.78 15.54 10.9 15.54 8.66663Z"
          />
          <path
            fill="currentColor"
            d="M8.49999 15.8333c1.98001 0 3.64001-.6567 4.85331-1.7767l-2.37-1.84c-.6566.44-1.49665.7-2.48331.7-1.91 0-3.52667-1.29-4.10334-3.02329H1.94666v1.89999c1.20666 2.3967 3.68666 4.04 6.55333 4.04Z"
          />
          <path
            fill="currentColor"
            d="M4.39669 9.89333c-.14667-.44-.23-.91-.23-1.39333 0-.48333.08333-.95334.23-1.39333v-1.9h-2.45a7.330137 7.330137 0 0 0-.78 3.29333c0 1.18333.28333 2.3033.78 3.2933l2.45-1.89997Z"
          />
          <path
            fill="currentColor"
            d="M8.49999 4.08329c1.07666 0 2.04331.37 2.80331 1.09667l2.1034-2.10333c-1.27-1.18334-2.93-1.91-4.90671-1.91-2.86667 0-5.34667 1.64333-6.55333 4.04l2.44999 1.9c.57667-1.73334 2.19334-3.02334 4.10334-3.02334Z"
          />
        </svg>
      );
    case 'facebook':
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 17 17"
        >
          <path
            fill="currentColor"
            d="M16.5 8.50002c0-4.41828-3.5817-8.000002-8-8.000002-4.41828 0-8 3.581722-8 8.000002 0 3.99298 2.92548 7.30268 6.75 7.90278v-5.5903H5.21875V8.50002H7.25v-1.7625c0-2.005 1.19434-3.1125 3.0217-3.1125.8753 0 1.7908.15625 1.7908.15625v1.96875h-1.0088c-.9938 0-1.3037.61667-1.3037 1.24933v1.50067h2.2188l-.3547 2.31248H9.75v5.5903c3.8245-.6001 6.75-3.9098 6.75-7.90278Z"
          />
          <path
            fill="#fff"
            d="M11.6141 10.8125 11.9688 8.5H9.75V6.99933c0-.63266.3099-1.24933 1.3037-1.24933h1.0088V3.78125S11.147 3.625 10.2717 3.625C8.44434 3.625 7.25 4.7325 7.25 6.7375V8.5H5.21875v2.3125H7.25v5.5903c.4073.0639.82475.0972 1.25.0972s.8427-.0333 1.25-.0972v-5.5903h1.8641Z"
          />
        </svg>
      );
    case 'check':
      return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 9 7">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M.5 3.5 3 6l5-5"
          />
        </svg>
      );
    default:
      return null;
  }
};

export default Icon;
